import React from 'react';
import { connect } from 'react-redux';
import {
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { EditLocationSharp, Warning } from '@material-ui/icons';
import { colors } from '@apps/shared/src/style/colors';
import { RootState } from '../shared/types/types';
import { updateClaim, updateRepricing, updateQpa } from './claimsActions';
import { SaveMetadata } from './types/repricingTypes';
import { GridColumn } from '../shared/grid';
import TextInput from './TextInput';

type StateProps = {
  claimID: string;
};
function mapStateToProps({ claims }: RootState): StateProps {
  return {
    claimID: claims.selectedClaim.id,
  };
}
type DispatchProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateClaim: (updateInfo: any) => void;
  updateRepricing: (updateInfo: any) => void;
  updateQpa: (updateInfo: any) => void;
};
const mapDispatchToProps = { updateClaim, updateRepricing, updateQpa };
type ParentProps = {
  showAllValidation: boolean;
  svc: any;
  isDisabled: boolean;
  id: number;
  saveMetadata: SaveMetadata;
  updateRepricing: Function;
  showqpa: boolean;
  errMsg: string;
  swappedRepriceWithQPA: boolean;
};
type Props = StateProps & DispatchProps & ParentProps;
export function RepriceInput({
  showAllValidation,
  svc,
  isDisabled,
  saveMetadata,
  id,
  updateRepricing,
  showqpa,
  errMsg,
  swappedRepriceWithQPA,
  updateQpa,
}: Props): JSX.Element {
  const [toggleDialog, setToggleDialog] = React.useState<boolean>(false);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [isEditable, setIsEditable] = React.useState<boolean>(false);
  const [isZipCodeEdit, setIsZipCodeEdit] = React.useState<boolean>(false);
  const [inValidZipCode, setInvalidZipCode] = React.useState<boolean>(true);
  const [zipCode, setZipCode] = React.useState<number>();
  const { validation = {}, allValidation = {}, repricing = {} } = svc;
  // This function is triggered when the select changes
  const handleClose = (): void => {
    setToggleDialog(false);
    setShowConfirm(false);
  };
  const handleEditReprice = (): void => {
    /// Hit API

    if (isZipCodeEdit) {
      updateQpa({
        ...saveMetadata,
        qpaZipCode: String(zipCode),
      });
      window.location.reload();
    }
    setShowConfirm(false);
    setIsEditable(true);
  };

  const editZipCode = (): void => {
    setToggleDialog(true);
  };

  const openConfirm = (): void => {
    setShowConfirm(true);
    setToggleDialog(false);
  };
  const openConfirmByZipCode = (): void => {
    setIsZipCodeEdit(true);
    setShowConfirm(true);
    setToggleDialog(false);
  };

  const handleChange = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.value = e.target.value.replace(/[\D\s]/, '');

    if (e.target.value === '' || e.target.value.length <= 5) {
      setZipCode(e.target.value);
      setInvalidZipCode(false);
    }

    if (e.target.value.length < 5) {
      setInvalidZipCode(true);
    }
  };
  const consentText = `Are you sure you want to edit the
    ${!swappedRepriceWithQPA ? 'QPA' : ''}
     Repriced Amount?`;
  const dialgHeaderText = `Edit ${!swappedRepriceWithQPA ? 'QPA' : ''} Repriced Amount?`;
  return (
    <>
      <Dialog open={showConfirm} onClose={handleClose}>
        <DialogTitle>{dialgHeaderText}</DialogTitle>
        <DialogContent>
          <DialogContentText>{consentText} </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleEditReprice}>Yes</Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={toggleDialog} onClose={handleClose}>
        <DialogTitle>Edit QPA</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DialogContentText>
              Enter new zip code and click &quot;Submit&quot;. This will overwrite QPA Repriced
              Amount.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              onChange={e => handleChange(e)}
              value={zipCode}
              id="sbiReason"
              label="New zip code to calculate"
              fullWidth
              variant="standard"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={inValidZipCode} onClick={openConfirmByZipCode}>
            Submit
          </Button>
        </DialogActions>
      </Dialog> */}

      {showqpa ? (
        <>
          <GridColumn>
            <TextInput
              isEditable={!isEditable}
              id={`${id}RepriceTotal`}
              disabled={isDisabled}
              onClick={openConfirm}
              value={repricing.total}
              isMoney
              fullWidth
              validation={{
                ...validation,
                ...allValidation,
                showValidation: validation.showValidation || showAllValidation,
              }}
              saveMetadata={{
                ...saveMetadata,
                fieldName: 'repricing.total',
              }}
              save={updateRepricing}
            />
          </GridColumn>
          <GridColumn>
            {/* <Tooltip
              placement="top-start"
              title={<p style={{ fontSize: '14px' }}> Edit QPA by Zip Code </p>}
            >
              <EditLocationSharp
                style={{
                  color: colors.grey37,
                  fontSize: '35',
                  marginLeft: '-10%',
                  cursor: 'pointer',
                }}
                onClick={editZipCode}
              />
            </Tooltip> */}
            {swappedRepriceWithQPA && errMsg == null && (
              <GridColumn>
                <Tooltip
                  placement="top-start"
                  title={<p style={{ fontSize: '14px' }}> QPA is repriced amount </p>}
                >
                  <Warning style={{ color: colors.orange }} />
                </Tooltip>
              </GridColumn>
            )}
            {errMsg != null && swappedRepriceWithQPA && (
              <GridColumn>
                <Tooltip
                  placement="top-start"
                  title={<p style={{ fontSize: '14px' }}> {errMsg} </p>}
                >
                  <Warning style={{ color: colors.orange }} />
                </Tooltip>
              </GridColumn>
            )}
          </GridColumn>
        </>
      ) : (
        <>
          <GridColumn>
            <TextInput
              id={`${id}RepriceTotal`}
              disabled={isDisabled}
              value={repricing.total}
              isMoney
              fullWidth
              validation={{
                ...validation,
                ...allValidation,
                showValidation: validation.showValidation || showAllValidation,
              }}
              saveMetadata={{
                ...saveMetadata,
                fieldName: 'repricing.total',
              }}
              save={updateRepricing}
            />
          </GridColumn>
          {swappedRepriceWithQPA && errMsg && (
            <GridColumn>
              <Tooltip placement="top-start" title={<p style={{ fontSize: '14px' }}> {errMsg} </p>}>
                <Warning style={{ color: colors.orange }} />
              </Tooltip>
            </GridColumn>
          )}
        </>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RepriceInput);
