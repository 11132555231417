import { clearRedirect, createProfile, login } from '@apps/shared/src/auth/userActions';
import UserCard from '@apps/shared/src/auth/UserCard';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const styles = {
  inputWrapper: {
    width: '100%',
    marginTop: '10px',
  },
};

class CreateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      password: '',
      rePassword: '',
      isValid: false,
      nameDirty: false,
      passDirty: false,
      rePassDirty: false,
      errors: {
        fullName: '',
        password: '',
        rePassword: '',
        agreeCheck: '',
      },
    };
  }

  componentWillUnmount() {
    this.props.dispatch(clearRedirect()); // Clear any redirect before leaving
  }

  setValue = (name, validate) => e => {
    if (validate) this.setState({ [name]: e.target.value }, this.validate);
    else this.setState({ [name]: e.target.value });
  };

  validate = () => {
    const errors = {
      fullName: '',
      password: '',
      rePassword: '',
    };
    const { fullName, password, rePassword, passDirty, rePassDirty, nameDirty } = this.state;

    if (fullName.trim() !== fullName) errors.fullName = 'No leading or trailing whitespace';
    else if (nameDirty && fullName.trim().length < 3) errors.fullName = 'Minimum 3 characters';

    if (password.trim() !== password) errors.password = 'No leading or trailing whitespace';
    if (passDirty) {
      if (password.length < 7) errors.password = 'Minimum 7 characters';
      else if (password.length > 128) errors.password = 'Maximum 128 characters';
    }

    if (rePassDirty && rePassword?.length > 0 && password !== rePassword)
      errors.rePassword = 'Passwords must match';

    this.setState({ errors });

    const valCheck =
      this.state.password.trim().length > 0 &&
      this.state.rePassword.trim().length > 0 &&
      this.state.fullName.trim().length > 0;
    const errCount = Object.values(errors).filter(x => x !== '').length > 0;
    const defaultEmailCheck = this.props.registerEmail !== undefined;
    return this.setState({ isValid: !errCount && valCheck && defaultEmailCheck });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.isValid) {
      this.createProfile();
    }
  };

  login = () => {
    this.props.dispatch(
      login({
        email: this.props.registerEmail,
        password: this.state.password,
        destinationURL: '/repricing',
      })
    );
  };

  createProfile = () => {
    this.props.dispatch(createProfile(this.state.fullName, this.state.password, this.props.app));
  };

  render() {
    const { classes } = this.props;

    const form = (
      <form onSubmit={this.handleSubmit} style={{ width: '100%', margin: 'auto' }}>
        <div style={{ maxWidth: '250px', margin: 'auto', textAlign: 'left' }}>
          <FormControl className={classes.inputWrapper} error={!!this.state.errors.fullName}>
            <InputLabel htmlFor="fullName">Full Name</InputLabel>
            <Input
              id="fullName"
              value={this.state.fullName}
              onChange={this.setValue('fullName')}
              onBlur={() => this.validate()}
              onFocus={() => this.setState({ nameDirty: true })}
              label="Full Name"
              fullWidth
              style={{ maxWidth: '250px' }}
              aria-describedby="fullNameErrorText"
            />
            <FormHelperText id="fullNameErrorText">{this.state.errors.fullName}</FormHelperText>
          </FormControl>

          <FormControl
            className={classes.inputWrapper}
            error={!!this.state.errors.email}
            style={{ paddingBottom: '20px' }}
          >
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" value={this.props.registerEmail} label="Email" readOnly fullWidth />
          </FormControl>

          <FormControl className={classes.inputWrapper} error={!!this.state.errors.password}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              value={this.state.password}
              onChange={this.setValue('password')}
              onBlur={() => this.validate()}
              onFocus={() => this.setState({ passDirty: true })}
              type="password"
              fullWidth
              aria-describedby="passwordErrorText"
            />
            <FormHelperText id="passwordErrorText">{this.state.errors.password}</FormHelperText>
          </FormControl>

          <FormControl className={classes.inputWrapper} error={!!this.state.errors.rePassword}>
            <InputLabel htmlFor="rePassword">Re-enter Password</InputLabel>
            <Input
              id="rePassword"
              value={this.state.rePassword}
              onChange={this.setValue('rePassword', true)}
              onFocus={() => this.setState({ rePassDirty: true })}
              onBlur={() => this.validate()}
              type="password"
              fullWidth
              style={{ maxWidth: '250px' }}
              aria-describedby="rePasswordErrorText"
            />
            <FormHelperText id="rePasswordErrorText">{this.state.errors.rePassword}</FormHelperText>
          </FormControl>
        </div>
        <Button
          style={{ margin: '40px auto 0', maxWidth: '350px' }}
          variant="contained"
          type="submit"
          color="primary"
          disabled={!this.state.isValid}
          fullWidth
        >
          CREATE ACCOUNT
        </Button>
      </form>
    );

    return this.props.redirectTo !== '' ? (
      <Redirect to={this.props.redirectTo} />
    ) : (
      <UserCard showLogo={!this.state.showTOS}>{form}</UserCard>
    );
  }
}

CreateProfile.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  registerEmail: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
};
CreateProfile.defaultProps = {
  redirectTo: '',
};

function mapStateToProps(state) {
  return {
    redirectTo: state.user.redirectTo,
    registerEmail: state.user.currentUser?.email,
    app: state.admin.app,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CreateProfile));
