import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import * as types from '../../shared/types/propTypes';

import TextInput from '../../shared/components/TextInput';
import {
  TableCellHeaderTop,
  Column,
  configSectionStyle,
  deleteButtonStyle,
  configIconStyle,
} from './sharedConfig';

const useStyles = makeStyles({
  configSection: configSectionStyle,
  configIcon: configIconStyle,
  claimInfoCell: {
    margin: '10px',
  },
  deleteButton: deleteButtonStyle,
});

export default function Patient834Config({
  patient834Config,
  isReadOnly,
  handleTextChange,
  handleUnSetObject,
}) {
  const classes = useStyles();

  if (!patient834Config) {
    return null;
  }

  return (
    <Paper className={classes.claimInfoCell}>
      <TableCellHeaderTop>
        <Typography>
          <AccountTreeIcon className={classes.configIcon} /> &nbsp;Patient 834 Config
        </Typography>
        {!isReadOnly && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.deleteButton}
            onClick={handleUnSetObject('patient834Config')}
          >
            <DeleteForeverIcon /> &nbsp; 834 Config
          </Button>
        )}
      </TableCellHeaderTop>
      <Column>
        <div className={classes.configSection}>
          <TextInput
            readOnly={isReadOnly}
            label="SubscriberSSNQual"
            aria-label="SubscriberSSNQual"
            fullWidth
            onChange={handleTextChange('patient834Config.subscriberSSNQual')}
            value={patient834Config.subscriberSSNQual}
          />
          <TextInput
            readOnly={isReadOnly}
            label="SubscriberIDQual"
            aria-label="SubscriberIDQual"
            fullWidth
            onChange={handleTextChange('patient834Config.subscriberIDQual')}
            value={patient834Config.subscriberIDQual}
          />
          <TextInput
            readOnly={isReadOnly}
            label="PatientIDQual"
            aria-label="PatientIDQual"
            fullWidth
            onChange={handleTextChange('patient834Config.patientIDQual')}
            value={patient834Config.patientIDQual}
          />
          <TextInput
            readOnly={isReadOnly}
            label="FamilyIDQual"
            aria-label="FamilyIDQual"
            fullWidth
            onChange={handleTextChange('patient834Config.familyIDQual')}
            value={patient834Config.familyIDQual}
          />
          <TextInput
            readOnly={isReadOnly}
            label="RelationCodeQual"
            aria-label="RelationCodeQual"
            fullWidth
            onChange={handleTextChange('patient834Config.relationCodeQual')}
            value={patient834Config.relationCodeQual}
          />
        </div>
      </Column>
    </Paper>
  );
}

Patient834Config.defaultProps = {
  patient834Config: null,
};

Patient834Config.propTypes = {
  patient834Config: types.patient834Config,
  isReadOnly: PropTypes.bool.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleUnSetObject: PropTypes.func.isRequired,
};
