import { getAxiosRequestConfig } from '@apps/shared/src/utilities';
import axios from 'axios';
import queryString from 'query-string';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_PENDING = `${GET_REPORT}_PENDING`;
export const GET_REPORT_FULFILLED = `${GET_REPORT}_FULFILLED`;
export const GET_REPORT_REJECTED = `${GET_REPORT}_REJECTED`;

export const GET_AGING_REPORT = 'GET_AGING_REPORT';
export const GET_AGING_REPORT_PENDING = `${GET_AGING_REPORT}_PENDING`;
export const GET_AGING_REPORT_FULFILLED = `${GET_AGING_REPORT}_FULFILLED`;
export const GET_AGING_REPORT_REJECTED = `${GET_AGING_REPORT}_REJECTED`;

export const GET_EXCEL_EXPORT = 'GET_EXCEL_EXPORT';
export const GET_EXCEL_EXPORT_PENDING = `${GET_EXCEL_EXPORT}_PENDING`;
export const GET_EXCEL_EXPORT_FULFILLED = `${GET_EXCEL_EXPORT}_FULFILLED`;
export const GET_EXCEL_EXPORT_REJECTED = `${GET_EXCEL_EXPORT}_REJECTED`;

export const UPDATE_SELECTED_TPAS = 'UPDATE_SELECTED_TPAS';
export const UPDATE_SELECTED_IDS = 'UPDATE_SELECTED_IDS';
export const SELECT_ALL_IDS = 'SELECT_ALL_IDS';
export const CLEAR_SELECTED_IDS = 'CLEAR_SELECTED_IDS';
export const TOGGLE_VIEW_SELECTED_CLAIMS = 'TOGGLE_VIEW_SELECTED_CLAIMS';
export const UPDATE_FILTERED_AGING = 'UPDATE_FILTERED_AGING';
export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER';
export const FORMAT_AND_VALIDATE_DATE = 'FORMAT_AND_VALIDATE_DATE';
export const SHOW_ONLY_AGING_CLAIMS = 'SHOW_ONLY_AGING_CLAIMS';
export const CHECK_DATE_RANGE = 'CHECK_DATE_RANGE';
export const UPDATE_AGING_SEARCH_TEXT = 'UPDATE_AGING_SEARCH_TEXT';
export const FILTER_PATIENT = 'FILTER_PATIENT';
export const SORT_PATIENT = 'SORT_PATIENT';
export const FILTER_INPROCESS_CLAIMS = 'FILTER_INPROCESS_CLAIMS';

export const GET_DUPLICATES = 'GET_DUPLICATES';
export const GET_DUPLICATES_PENDING = `${GET_DUPLICATES}_PENDING`;
export const GET_DUPLICATES_FULFILLED = `${GET_DUPLICATES}_FULFILLED`;
export const GET_DUPLICATES_REJECTED = `${GET_DUPLICATES}_REJECTED`;

export const GET_DAILY_CLAIMS = 'GET_DAILY_CLAIMS';
export const GET_DAILY_CLAIMS_PENDING = `${GET_DAILY_CLAIMS}_PENDING`;
export const GET_DAILY_CLAIMS_FULFILLED = `${GET_DAILY_CLAIMS}_FULFILLED`;
export const GET_DAILY_CLAIMS_REJECTED = `${GET_DAILY_CLAIMS}_REJECTED`;

export const GET_INPROCESS_CLAIMS = 'GET_INPROCESS_CLAIMS';
export const GET_INPROCESS_CLAIMS_PENDING = `${GET_INPROCESS_CLAIMS}_PENDING`;
export const GET_INPROCESS_CLAIMS_FULFILLED = `${GET_INPROCESS_CLAIMS}_FULFILLED`;
export const GET_INPROCESS_CLAIMS_REJECTED = `${GET_INPROCESS_CLAIMS}_REJECTED`;

export const UNASSIGN_CLAIM_OWNER = 'UNASSIGN_CLAIM_OWNER';
export const UNASSIGN_CLAIM_OWNER_PENDING = `${UNASSIGN_CLAIM_OWNER}_PENDING`;
export const UNASSIGN_CLAIM_OWNER_FULFILLED = `${UNASSIGN_CLAIM_OWNER}_FULFILLED`;
export const UNASSIGN_CLAIM_OWNER_REJECTED = `${UNASSIGN_CLAIM_OWNER}_REJECTED`;

export const GET_PATIENT_REPORT = 'GET_PATIENT_REPORT';
export const GET_PATIENT_REPORT_PENDING = `${GET_PATIENT_REPORT}_PENDING`;
export const GET_PATIENT_REPORT_FULFILLED = `${GET_PATIENT_REPORT}_FULFILLED`;
export const GET_PATIENT_REPORT_REJECTED = `${GET_PATIENT_REPORT}_REJECTED`;

export const GET_PLAN_REPORT = 'GET_PLAN_REPORT';
export const GET_PLAN_REPORT_PENDING = `${GET_PLAN_REPORT}_PENDING`;
export const GET_PLAN_REPORT_FULFILLED = `${GET_PLAN_REPORT}_FULFILLED`;
export const GET_PLAN_REPORT_REJECTED = `${GET_PLAN_REPORT}_REJECTED`;

export const UPDATE_EXT_STATUS_NOTE = 'UPDATE_EXT_STATUS_NOTE';
export const UPDATE_EXT_STATUS_NOTE_PENDING = `${UPDATE_EXT_STATUS_NOTE}_PENDING`;
export const UPDATE_EXT_STATUS_NOTE_FULFILLED = `${UPDATE_EXT_STATUS_NOTE}_FULFILLED`;
export const UPDATE_EXT_STATUS_NOTE_REJECTED = `${UPDATE_EXT_STATUS_NOTE}_REJECTED`;

export const GET_FILE = 'GET_FILE';
export const GET_FILE_PENDING = `${GET_FILE}_PENDING`;
export const GET_FILE_FULFILLED = `${GET_FILE}_FULFILLED`;
export const GET_FILE_REJECTED = `${GET_FILE}_REJECTED`;

export const GET_FILES = 'GET_FILES';
export const GET_FILES_PENDING = `${GET_FILES}_PENDING`;
export const GET_FILES_FULFILLED = `${GET_FILES}_FULFILLED`;
export const GET_FILES_REJECTED = `${GET_FILES}_REJECTED`;

export const GET_FILES_V2 = 'GET_FILES_V2';
export const GET_FILES_V2_PENDING = `${GET_FILES_V2}_PENDING`;
export const GET_FILES_V2_FULFILLED = `${GET_FILES_V2}_FULFILLED`;
export const GET_FILES_V2_REJECTED = `${GET_FILES_V2}_REJECTED`;

export const GET_MONTHLY_REPORTING_PREFLIGHT = 'GET_MONTHLY_REPORTING_PREFLIGHT';
export const GET_MONTHLY_REPORTING_PREFLIGHT_PENDING = `${GET_MONTHLY_REPORTING_PREFLIGHT}_PENDING`;
export const GET_MONTHLY_REPORTING_PREFLIGHT_FULFILLED = `${GET_MONTHLY_REPORTING_PREFLIGHT}_FULFILLED`;
export const GET_MONTHLY_REPORTING_PREFLIGHT_REJECTED = `${GET_MONTHLY_REPORTING_PREFLIGHT}_REJECTED`;

export const GENERATE_MONTHLY_REPORT = 'GENERATE_MONTHLY_REPORT';
export const GENERATE_MONTHLY_REPORT_PENDING = `${GENERATE_MONTHLY_REPORT}_PENDING`;
export const GENERATE_MONTHLY_REPORT_FULFILLED = `${GENERATE_MONTHLY_REPORT}_FULFILLED`;
export const GENERATE_MONTHLY_REPORT_REJECTED = `${GENERATE_MONTHLY_REPORT}_REJECTED`;

export const RESOLVE_MONTHLY_REPORTING_DUPLICATE = 'RESOLVE_MONTHLY_REPORTING_DUPLICATE';
export const RESOLVE_MONTHLY_REPORTING_DUPLICATE_PENDING = `${RESOLVE_MONTHLY_REPORTING_DUPLICATE}_PENDING`;
export const RESOLVE_MONTHLY_REPORTING_DUPLICATE_FULFILLED = `${RESOLVE_MONTHLY_REPORTING_DUPLICATE}_FULFILLED`;
export const RESOLVE_MONTHLY_REPORTING_DUPLICATE_REJECTED = `${RESOLVE_MONTHLY_REPORTING_DUPLICATE}_REJECTED`;

export const GET_INVOICE_REPORT = 'GET_INVOICE_REPORT';
export const GET_INVOICE_REPORT_PENDING = `${GET_INVOICE_REPORT}_PENDING`;
export const GET_INVOICE_REPORT_FULFILLED = `${GET_INVOICE_REPORT}_FULFILLED`;
export const GET_INVOICE_REPORT_REJECTED = `${GET_INVOICE_REPORT}_REJECTED`;

export const GET_CLAIM_TRAFFIC_REPORT = 'GET_CLAIM_TRAFFIC_REPORT';
export const GET_CLAIM_TRAFFIC_REPORT_PENDING = `${GET_CLAIM_TRAFFIC_REPORT}_PENDING`;
export const GET_CLAIM_TRAFFIC_REPORT_FULFILLED = `${GET_CLAIM_TRAFFIC_REPORT}_FULFILLED`;
export const GET_CLAIM_TRAFFIC_REPORT_REJECTED = `${GET_CLAIM_TRAFFIC_REPORT}_REJECTED`;

export const GET_ALL_PLANS_CT = 'GET_ALL_PLANS_CT';
export const GET_ALL_PLANS_CT_PENDING = `${GET_ALL_PLANS_CT}_PENDING`;
export const GET_ALL_PLANS_CT_FULFILLED = `${GET_ALL_PLANS_CT}_FULFILLED`;
export const GET_ALL_PLANS_CT_REJECTED = `${GET_ALL_PLANS_CT}_REJECTED`;

export const GET_ALL_TPAS_CT = 'GET_ALL_TPAS_CT';
export const GET_ALL_TPAS_CT_PENDING = `${GET_ALL_TPAS_CT}_PENDING`;
export const GET_ALL_TPAS_CT_FULFILLED = `${GET_ALL_TPAS_CT}_FULFILLED`;
export const GET_ALL_TPAS_CT_REJECTED = `${GET_ALL_TPAS_CT}_REJECTED`;
export const GET_EXCEPTIONS_REPORT = 'GET_EXCEPTIONS_REPORT';
export const GET_EXCEPTIONS_REPORT_PENDING = `${GET_EXCEPTIONS_REPORT}_PENDING`;
export const GET_EXCEPTIONS_REPORT_FULFILLED = `${GET_EXCEPTIONS_REPORT}_FULFILLED`;
export const GET_EXCEPTIONS_REPORT_REJECTED = `${GET_EXCEPTIONS_REPORT}_REJECTED`;

export const GET_TPA_EXCLUSIONS = 'GET_TPA_EXCLUSIONS';
export const GET_TPA_EXCLUSIONS_PENDING = `${GET_TPA_EXCLUSIONS}_PENDING`;
export const GET_TPA_EXCLUSIONS_FULFILLED = `${GET_TPA_EXCLUSIONS}_FULFILLED`;
export const GET_TPA_EXCLUSIONS_REJECTED = `${GET_TPA_EXCLUSIONS}_REJECTED`;

// ------------ EXPORT to EXCEL ------------
function getExcelExport(reportType, meta, filename) {
  return {
    type: GET_EXCEL_EXPORT,
    payload: axios.put(`/api/export/${reportType}`, meta, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      responseType: 'blob',
    }),
    meta: { ...meta, filename },
  };
}

// ------------ ALL CLAIMS ------------
export function getAllClaimsReport(month) {
  return {
    type: GET_REPORT,
    payload: axios.get(`/api/report/${month}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function exportAllClaimsReport(month) {
  return getExcelExport('all-claims', { month }, `All Claims Report-${month}.xlsx`);
}

// ------------ % SAVINGS / 837 INVOICES ------------
export function getInvoiceReport(month) {
  return {
    type: GET_INVOICE_REPORT,
    payload: axios.get(`/api/report-invoices/${month}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function exportInvoiceReport(month) {
  return getExcelExport('invoices', { month }, `837 Invoices Report-${month}.xlsx`);
}

// ------------ AGING ------------
export function getAgingReport() {
  return (dispatch, getState) => {
    const query = getAgingQueryFromState(getState());
    return dispatch({
      type: GET_AGING_REPORT,
      payload: axios.get(`/api/aging-report?${queryString.stringify(query)}`, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
      meta: query,
    });
  };
}

export function exportAgingReport() {
  return (dispatch, getState) => {
    const query = getAgingQueryFromState(getState());
    const tpaList =
      query.senderName && query.senderName.length > 0 ? [...query.senderName] : ['All TPAs'];
    const i = tpaList.indexOf('');
    if (i !== -1) tpaList[i] = 'Manual Claims';
    const filename = `Aging Claims Report-${tpaList}.xlsx`;
    dispatch(getExcelExport('aging', query, filename));
  };
}

export const updateExtStatusNote = updateInfo => {
  return {
    type: UPDATE_EXT_STATUS_NOTE,
    meta: updateInfo,
    payload: axios.put(`/api/claim/${updateInfo.claimID}`, updateInfo, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
};

function getAgingQueryFromState(state) {
  const {
    startDateFilter,
    endDateFilter,
    startDateErr,
    endDateErr,
    selectedIDs,
    selectedTPAs,
    filteredAging,
    showOnlyAging,
    excludedStatuses,
  } = state.reports;
  return {
    senderName: selectedTPAs,
    excludedStatuses:
      showOnlyAging && excludedStatuses && excludedStatuses.length > 0
        ? excludedStatuses
        : undefined,
    // twoDaysAgo: showOnlyAging ? true : undefined,
    priorityStatus: showOnlyAging ? true : undefined,
    id:
      selectedIDs && selectedIDs.length > 0
        ? selectedIDs.filter(id => filteredAging.find(c => c.id === id))
        : undefined,
    startDate:
      !showOnlyAging && startDateFilter && startDateFilter !== '' && !startDateErr
        ? startDateFilter
        : undefined,
    endDate:
      !showOnlyAging && endDateFilter && endDateFilter !== '' && !endDateErr
        ? endDateFilter
        : undefined,
  };
}

export const updateFilteredAging = () => {
  return {
    type: UPDATE_FILTERED_AGING,
  };
};

export function updateAndFilterAging(action) {
  return dispatch => {
    return new Promise(resolve => {
      dispatch(action);
      dispatch(updateFilteredAging());
      resolve();
    });
  };
}

export const showOnlyAgingClaims = () => {
  return {
    type: SHOW_ONLY_AGING_CLAIMS,
  };
};

export const updateSelectedTPAs = value => {
  return {
    type: UPDATE_SELECTED_TPAS,
    payload: { value },
  };
};

export const updateAgingSearchText = agingSearchText => {
  return {
    type: UPDATE_AGING_SEARCH_TEXT,
    payload: { agingSearchText },
  };
};

export const updateSelectedIDs = id => {
  return {
    type: UPDATE_SELECTED_IDS,
    payload: { id },
  };
};

export const selectAllIDs = () => {
  return {
    type: SELECT_ALL_IDS,
  };
};

export const clearSelectedIDs = ids => {
  return {
    type: CLEAR_SELECTED_IDS,
    payload: { ids },
  };
};

export const toggleViewSelectedClaims = () => {
  return {
    type: TOGGLE_VIEW_SELECTED_CLAIMS,
  };
};

const formatDate = (dispatch, formattedDate, whichDate) =>
  new Promise(resolve => {
    dispatch({
      type: FORMAT_AND_VALIDATE_DATE,
      payload: { formattedDate, whichDate },
    });
    resolve();
  });

const checkDateRange = dispatch =>
  new Promise(resolve => {
    dispatch({
      type: CHECK_DATE_RANGE,
    });
    resolve();
  });

const shouldCheckDateRange = (startDateError, endDateError) => {
  if (startDateError === 'Invalid Date' || endDateError === 'Invalid Date') return false;
  return true;
};

export function formatReportDate(formattedDate, whichDate) {
  return (dispatch, getState) => {
    const {
      reports: { startDateFilter, endDateFilter },
    } = getState();
    const newDate = whichDate === 'startDate' ? startDateFilter : endDateFilter;

    if (formattedDate !== newDate) {
      formatDate(dispatch, formattedDate, whichDate)
        .then(() => {
          const {
            reports: { startDateError, endDateError },
          } = getState(); // receives updates from formatDate
          if (shouldCheckDateRange(startDateError, endDateError)) {
            checkDateRange(dispatch);
          }
        })
        .then(() => {
          const {
            reports: { startDateError, endDateError, startDateFilter },
          } = getState(); // receives updates from checkDateRange
          if (startDateError.length === 0 && endDateError.length === 0 && startDateFilter !== '') {
            dispatch(getAgingReport()).then(() => dispatch(updateFilteredAging()));
          }
        });
    }
  };
}

// ------------ DUPLICATES ------------
export function getDuplicates(month) {
  return {
    type: GET_DUPLICATES,
    payload: axios.get(`/api/report-duplicates/${month || 'all'}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

// ------------ DAILY CLAIMS ------------
export function getDailyClaims() {
  return {
    type: GET_DAILY_CLAIMS,
    payload: axios.get(`/api/report-daily`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

// ------------ IN-PROCESS CLAIMS REPORT ------------
export function getInProcessClaimsReport() {
  return {
    type: GET_INPROCESS_CLAIMS,
    payload: axios.get(`/api/report-inprocess`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export const filterInProcess = inProcessSearchText => {
  return {
    type: FILTER_INPROCESS_CLAIMS,
    payload: { inProcessSearchText },
  };
};

export function getInProcessClaimsAndFilter(filter) {
  return dispatch => {
    dispatch(getInProcessClaimsReport()).then(() => dispatch(filterInProcess(filter)));
  };
}

export const unassignClaimOwner = claimID => {
  return {
    type: UNASSIGN_CLAIM_OWNER,
    meta: claimID,
    payload: axios.put(`/api/claim/${claimID}/unassign-owner`, null, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
};

// ------------ PATIENT ------------
export function getPatientReport(
  pageNum = 1,
  pageSize = 100,
  firstName = null,
  lastName = null,
  dateOfBirth = null,
  patientID = null,
  subscriberID = null,
  censusName = null
) {
  return {
    type: GET_PATIENT_REPORT,
    payload: axios.get(`/api/report-patients`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      params: {
        pageSize,
        pageNum,
        firstName,
        lastName,
        dateOfBirth,
        patientID,
        subscriberID,
        censusName,
      },
    }),
  };
}

export const filterPatient = (
  patientSearchFirstName,
  patientSearchLastName,
  searchDateofBirth,
  searchPatientID,
  searchSubscriberID,
  searchCensusName
) => {
  return {
    type: FILTER_PATIENT,
    payload: {
      patientSearchObject: {
        patientSearchFirstName,
        patientSearchLastName,
        searchDateofBirth,
        searchPatientID,
        searchSubscriberID,
        searchCensusName,
      },
    },
  };
};

export function getPatientReportAndFilter(filter, pageNum = 1, pageSize = 100) {
  return dispatch => {
    return dispatch(
      getPatientReport(
        pageNum,
        pageSize,
        filter.patientSearchFirstName,
        filter.patientSearchLastName,
        filter.searchDateofBirth,
        filter.searchPatientID,
        filter.searchSubscriberID,
        filter.searchCensusName
      )
    ).then(() => dispatch(filterPatient(filter)));
  };
}

export function sortPatient(sortBy, sortDirection) {
  return {
    type: SORT_PATIENT,
    payload: {
      sortBy,
      sortDirection,
    },
  };
}

// ------------ PLAN ------------
export function getPlanReport() {
  return {
    type: GET_PLAN_REPORT,
    payload: axios.get(`/api/report-plans`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

// ------------ BATCHES ------------

export function getFile(id) {
  return {
    type: GET_FILE,
    payload: axios.get(`/api/file/${id}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getFiles(searchTerm) {
  const filter = searchTerm || '';
  return {
    type: GET_FILES,
    payload: axios.get(`/api/files?filter=${filter}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
    meta: filter,
  };
}

export function getFilesV2(searchFilter) {
  const filter = searchFilter || {};
  return {
    type: GET_FILES_V2,
    payload: axios.get('/api/v2/files', {
      ...getAxiosRequestConfig(),
      params: filter,
    }),
    meta: filter,
  };
}

// ------------ MONTHLY REPORTING ------------
export function getMonthlyReportingPreflight() {
  return {
    type: GET_MONTHLY_REPORTING_PREFLIGHT,
    payload: axios.get(`/api/report-monthly/wizard`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function resolveMonthlyReportingDuplicate(claimId, isKeepDuplicate, groupIndex, claimIndex) {
  return {
    type: RESOLVE_MONTHLY_REPORTING_DUPLICATE,
    payload: axios.patch(
      `/api/report-monthly/resolve-duplicate/${claimId}`,
      {
        keepDuplicate: isKeepDuplicate,
      },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
    meta: { isKeepDuplicate, groupIndex, claimIndex },
  };
}

export function generateMonthlyReport() {
  return {
    type: GENERATE_MONTHLY_REPORT,
    payload: axios.post(
      '/api/report-monthly/generate',
      {},
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

// ------------ CLAIM TRAFFIC ------------

export function getClaimTrafficReport(reportType, id, startDate, endDate, filename) {
  const params = {};
  if (startDate !== '') {
    params.startDate = startDate;
  }
  if (endDate !== '') {
    params.endDate = endDate;
  }

  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${new URLSearchParams(params).toString()}`;
  }

  return {
    type: GET_CLAIM_TRAFFIC_REPORT,
    payload: axios.get(`/api/claim-traffic/${reportType}/${id}${query}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      responseType: 'blob',
    }),
    meta: { filename: `${filename}.xlsx` },
  };
}

export function getAllTPAs() {
  return {
    type: GET_ALL_TPAS_CT,
    payload: axios.get('/api/tpas', {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getAllPlans() {
  return {
    type: GET_ALL_PLANS_CT,
    payload: axios.get('/api/plans', {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

// ------------ EXCEPTIONS ------------
export function getExceptionsReport(startDate = '', endDate = '') {
  return {
    type: GET_EXCEPTIONS_REPORT,
    payload: axios.get(`/api/exceptions/v1?start-date=${startDate}&end-date=${endDate}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getTPAExclusions() {
  return {
    type: GET_TPA_EXCLUSIONS,
    payload: axios.get(`/api/exclusions/v1`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}
