import LoginForm from '@apps/shared/src/auth/LoginForm';
import React from 'react';
import UserCard from '@apps/shared/src/auth/UserCard';

const Login: React.FC = (): JSX.Element => (
  <UserCard showLogo>
    <LoginForm hideRegistration />
  </UserCard>
);

export default Login;
