import { clearRedirect, redirectTo, resetPassword } from '@apps/shared/src/auth/userActions';
import UserCard from '@apps/shared/src/auth/UserCard';
import LoadingIndicator from '@apps/shared/src/components/LoadingIndicator';
import { colors } from '@apps/shared/src/style/colors';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const styles = {
  inputWrapper: {
    width: '100%',
    marginTop: '10px',
  },
  ahref: {
    textDecoration: 'none',
  },
};

class CreateNewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      rePassword: '',
      errors: {
        password: '',
        rePassword: '',
      },
      hasMinChar: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(clearRedirect()); // Clear any redirect before leaving
  }

  setValue = name => e => this.setState({ [name]: e.target.value });

  // Move validation to the reducer
  validate = () => {
    const errors = {
      password: '',
      rePassword: '',
    };
    const { password, rePassword } = this.state;

    if (password.trim() !== password) errors.password = 'No leading or trailing whitespace';
    else if (password.length < 12) errors.password = 'Minimum 12 characters';
    else if (password.length > 128) errors.password = 'Maximum 128 characters';

    if (password !== rePassword) errors.rePassword = 'Passwords must match';

    this.setState({ errors });

    const errValues = Object.values(errors);
    for (const err of errValues) {
      if (err !== '') return false;
    }
    return true;
  };

  checkPassword = password => {
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let hasMinChar = false;

    if (password.length >= 12) {
      hasMinChar = true;
    }
    if (/[A-Z]/.test(password)) {
      hasUppercase = true;
    }
    if (/[a-z]/.test(password)) {
      hasLowercase = true;
    }
    if (/[0-9]/.test(password)) {
      hasNumber = true;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      hasSpecialChar = true;
    }

    this.setState({
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      hasMinChar,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) this.handlePasswordUpdate();
  };

  handlePasswordUpdate = () => this.props.dispatch(resetPassword(this.state.password));
  handleCancel = () => this.props.dispatch(redirectTo('/login'));

  handleChange = event => {
    const {
      target: { value },
    } = event;
    this.setState({ password: value });
    this.checkPassword(value);
  };

  render() {
    const { classes, isFetching } = this.props;
    const updatePasswordForm = (
      <form onSubmit={this.handleSubmit}>
        <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>
          Identity successfully verified
        </Typography>
        <Typography variant="body2" style={{ marginBottom: '1em' }}>
          Please enter an updated password to continue
        </Typography>
        <FormControl className={classes.inputWrapper} error={!!this.state.errors.password}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
            fullWidth
            aria-describedby="passwordErrorText"
          />
          <FormHelperText id="passwordErrorText">{this.state.errors.password}</FormHelperText>
          {this.state.password && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {[
                { label: '12 Characters', state: this.state.hasMinChar },
                { label: 'Uppercase', state: this.state.hasUppercase },
                { label: 'Lowercase', state: this.state.hasLowercase },
                { label: 'Number', state: this.state.hasNumber },
                { label: 'Special Character', state: this.state.hasSpecialChar },
              ].map(({ label, state }) => (
                <span key={label}>
                  <span style={{ color: state ? 'green' : 'red' }}>
                    {state ? '\u2714' : '\u2718'} <span style={{ color: 'black' }}>{label}</span>
                  </span>
                </span>
              ))}
            </div>
          )}
        </FormControl>

        <FormControl className={classes.inputWrapper} error={!!this.state.errors.rePassword}>
          <InputLabel htmlFor="rePassword">Re-enter Password</InputLabel>
          <Input
            id="rePassword"
            value={this.state.rePassword}
            onChange={this.setValue('rePassword')}
            type="password"
            fullWidth
            aria-describedby="rePasswordErrorText"
          />
          <FormHelperText id="rePasswordErrorText">{this.state.errors.rePassword}</FormHelperText>
        </FormControl>
        <Button
          style={{ margin: '3em auto 1em' }}
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
        >
          {isFetching && (
            <LoadingIndicator isLoading={isFetching} size={25} color={colors.orange} />
          )}
          {!isFetching && 'UPDATE PASSWORD'}
        </Button>
        {!isFetching && (
          <Button variant="contained" color="primary" fullWidth onClick={this.handleCancel}>
            CANCEL
          </Button>
        )}
      </form>
    );
    const continueLink = this.props.registerDestinationURL
      ? this.props.registerDestinationURL
      : '/login';
    const isMediVIMobile = continueLink === '/medivi-mobile-login';
    const passwordResetSuccess = (
      <>
        <Typography variant="subtitle1" style={{ marginBottom: '2em' }}>
          Congratulations, your password has been successfully reset.
        </Typography>
        {isMediVIMobile ? (
          <Typography>Please return to the MediVI Mobile app to continue.</Typography>
        ) : (
          <a
            href={continueLink}
            alt="Continue to application home"
            title="Continue to application home"
            className={classes.ahref}
          >
            <Button variant="contained" color="primary" fullWidth>
              LOG IN
            </Button>
          </a>
        )}
      </>
    );

    return this.props.redirectTo !== '' ? (
      <Redirect to={this.props.redirectTo} />
    ) : (
      <UserCard showLogo>
        {this.props.passwordResetSuccess ? passwordResetSuccess : updatePasswordForm}
      </UserCard>
    );
  }
}

CreateNewPassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  passwordResetSuccess: PropTypes.node,
  redirectTo: PropTypes.string,
  registerDestinationURL: PropTypes.string,
  isFetching: PropTypes.bool,
};
CreateNewPassword.defaultProps = {
  redirectTo: '',
  registerDestinationURL: '',
  passwordResetSuccess: undefined,
  isFetching: false,
};

function mapStateToProps(state) {
  return {
    redirectTo: state.user.redirectTo,
    passwordResetSuccess: state.user.passwordResetSuccess,
    registerDestinationURL: state.user.registerDestinationURL,
    isFetching: state.user.isFetching,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CreateNewPassword));
