import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import { debounce } from '@apps/shared/src/utilities';
import { colors } from '@apps/shared/src/style';

const styles = {
  container: {
    padding: '5px 16px',
    borderBottom: `1px solid ${colors.grey12}`,
    display: 'flex',
  },
};

class BatchSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
    };
    this.searchInput = React.createRef();
    this.debouncedGetFiles = debounce(this.props.getFiles, 400);
  }

  componentDidUpdate() {
    if (this.searchInput && this.searchInput.current) {
      this.searchInput.current.focus();
    }
  }

  handleChange = e => {
    const newSearchTerm = e.target.value;
    this.setState({ searchTerm: newSearchTerm });
    this.debouncedGetFiles(newSearchTerm);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <FormControl fullWidth>
          <Input
            inputRef={this.searchInput}
            onChange={this.handleChange}
            placeholder="Search all files"
            value={this.state.searchTerm}
          />
        </FormControl>
      </div>
    );
  }
}

BatchSearch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  getFiles: PropTypes.func.isRequired,
};

export default withStyles(styles)(BatchSearch);
