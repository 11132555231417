export const defaultSFTPServer = () => ({
  privateKeyPath: '',
  hostKeyPath: 'hostkeypath',
  username: '',
  password: '',
  serverAndPort: '',
  outgoingFolder: '',
  incomingFolder: '',
  reportsFolder: '',
  mrfFolder: '',
  fileTypeRestriction: '',
  disableReceive: false,
  useNonPreferredCiphers: false,
});

export const defaultPatientCSVConfig = () => ({
  delimiter: ',',
  header: true,
  headerValues: '',
  dateFormat: '',
  maxDateFormat: '',
  groupNumCol: 0,
  firstNameCol: 1,
  lastNameCol: 2,
  dateOfBirthCol: 3,
  genderCol: 4,
  patientSSNCol: 6,
  patientIDCol: 8,
  patientIDFormat: false,
  subscriberSSNCol: 5,
  subscriberIDCol: 7,
  subscriberIDFormat: '',
  addressCols: [11, 12],
  cityCol: 13,
  stateCol: 14,
  zipcodeCol: 15,
  countyCol: 16,
  phoneCol: [17],
  emailCol: [18],
  startDateCol: 19,
  startDateFormat: '',
  terminationDateCol: 20,
  terminationDateFormat: '',
  eligibleCol: -1,
  tpaRoutingCodeCol: -1,
});

export const default834Config = () => ({
  subscriberSSNQual: '',
  subscriberIDQual: '',
  patientIDQual: '',
  familyIDQual: '',
  relationCodeQual: '',
});

export const defaultPayerOverride = () => ({
  payerID: '',
  repriceSegmentName: '',
  nameOverride: '',
  doubleUsage: false,
});

export const defaultRepriceCodeMapping = () => ({
  element: 'XXX',
  code: 'XXX',
});

export const defaultMainClientConfig = () => ({
  name: '',
  sixDegTPAID: -1,
  localIncomingFolder: '',
  localOutgoingFolder: '',
  claimsBridgeRouteFolder: '',
  localReportsFolder: '',
  localMRFFolder: '',
  reportsEmails: '',
  subfolder999: '',
  suppress999: false,
  send837Invoice: false,
  send837InvoiceHCP: false,
  repriceSegmentName: 'HCP',
  useHCP04Codes: true,
  sendClaimHCP: true,
  nteCodeOverride: '',
  repriceZeroOutOfNetwork: false,
  professionalPrefix: '6DEGp',
  institutionalPrefix: '6DEGi',
  extension837: '.837',
  extension999: '.999',
  extension277: '.277',
  idQualifier: 'ZZ',
  interchangeID: '',
  pgpKeyPath: '',
});

export const defaultTPA = () => ({
  sixDegTPAID: 0,
  tpaName: '',
  accountID: '',
  nickname: '',
});
