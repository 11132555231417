import update from 'immutability-helper';
import { doesStringInclude, doesStringArrayIncludeString } from '@apps/shared/src/utilities';
import * as r from './reportsActions';
import { updateObject } from '../repricing/claimsReducer';
import { checkDate } from '../shared/validators';
import { SortDirection, isValidDate } from '../shared/globals';
import { claimStatusCodes } from '../shared/status';
import { validArray } from '../shared/typeChecks';

export const dateErrors = {
  needRange: 'Need a date range',
  startNotBeforeEnd: 'Start Date must be before End Date',
  moreThan90DaysFromToday: 'Date is more than 90 days ago',
  moreThan90Days: 'Range is more than 90 days',
};

const makeEmptyMonthlyReporting = () => ({
  month: '',
  year: 0,
  duplicatesMinBilledAmount: 0,
  duplicateGroups: [],
  errorMessage: '',
  isReportInProgress: false,
  numClaims: 0,
  isReportGenerated: false,
});

const makeEmptyExceptionsReport = () => ({
  dates: {},
  exceptions: [],
  exclusions: [],
});

export const reports = {
  report: [],
  allAging: [],
  filteredAging: [],
  allTPAsInReport: [],
  selectedTPAs: [],
  startDateFilter: '',
  endDateFilter: '',
  startDateError: '',
  endDateError: '',
  excludedStatuses: [
    claimStatusCodes.error,
    claimStatusCodes.complete,
    claimStatusCodes.verified,
    claimStatusCodes.resend,
  ],
  showOnlyAging: true,
  selectedIDs: [],
  viewSelectedClaims: false,
  agingSearchText: '',
  duplicates: [],
  dailyClaims: null,
  loading: false,
  isDownloading: false,
  // Patients Report
  patients: [],
  filteredPatients: [],
  patientSearchText: '',
  // In-Process Claims Report
  inProcessClaims: [],
  filteredInProcessClaims: [],
  inProcessSearchText: '',
  // Batches
  files: [],
  loadingFile: false,
  selectedFile: {},
  // Monthly Reporting
  monthlyReporting: makeEmptyMonthlyReporting(),
  // 837 Invoices
  invoiceReport: [],
  // Claim Traffic
  plans: [],
  tpas: [],
  // Exceptions
  exceptionsReport: makeEmptyExceptionsReport(),
};

export default (state = reports, action) => {
  switch (action.type) {
    case r.GET_REPORT_PENDING: {
      return { ...state, loading: true, isDownloading: false, report: [] };
    }

    case r.GET_REPORT_REJECTED:
    case r.GET_REPORT_FULFILLED: {
      return { ...state, loading: false, report: action.payload.data || [] };
    }

    case r.GET_INVOICE_REPORT_PENDING: {
      return { ...state, loading: true, isDownloading: false, report: [] };
    }

    case r.GET_INVOICE_REPORT_REJECTED:
    case r.GET_INVOICE_REPORT_FULFILLED: {
      return { ...state, loading: false, invoiceReport: action.payload.data || [] };
    }

    case r.GET_AGING_REPORT_PENDING: {
      return { ...state, loading: true };
    }
    case r.GET_AGING_REPORT_REJECTED: {
      return { ...state, loading: false };
    }
    case r.GET_AGING_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        allAging: getAllAging(action.payload.data),
        allTPAsInReport: getAllTPAsInReport(action.payload.data),
      };
    }

    case r.UPDATE_EXT_STATUS_NOTE_FULFILLED: {
      const m = action.meta;
      const fieldName = m.fieldName.replace('simpleClaim.', '');
      const allI = state.allAging.findIndex(clm => clm.id === m.claimID);
      const allAging =
        allI === -1
          ? state.allAging
          : [
              ...state.allAging.slice(0, allI),
              updateObject(state.allAging[allI], fieldName, m.stringVal),
              ...state.allAging.slice(allI + 1),
            ];
      const filteredI = state.filteredAging.findIndex(clm => clm.id === m.claimID);
      const filteredAging =
        filteredI === -1
          ? state.filteredAging
          : [
              ...state.filteredAging.slice(0, filteredI),
              updateObject(state.filteredAging[filteredI], fieldName, m.stringVal),
              ...state.filteredAging.slice(filteredI + 1),
            ];
      return { ...state, allAging, filteredAging };
    }

    case r.GET_CLAIM_TRAFFIC_REPORT_PENDING:
    case r.GET_EXCEL_EXPORT_PENDING:
      return { ...state, isDownloading: true };

    case r.GET_CLAIM_TRAFFIC_REPORT_REJECTED:
    case r.GET_EXCEL_EXPORT_REJECTED:
      return { ...state, isDownloading: false };

    case r.GET_CLAIM_TRAFFIC_REPORT_FULFILLED:
    case r.GET_EXCEL_EXPORT_FULFILLED: {
      const url = window.URL.createObjectURL(new Blob([action.payload.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', action.meta.filename);
      link.click();
      window.URL.revokeObjectURL(url);
      return { ...state, isDownloading: false };
    }

    case r.UPDATE_SELECTED_TPAS: {
      const { value } = action.payload;
      if (value === 'All') {
        return {
          ...state,
          selectedTPAs: [],
        };
      }
      let updateSelected = [...state.selectedTPAs];
      if (state.selectedTPAs.includes(value))
        updateSelected = state.selectedTPAs.filter(tpa => tpa !== value);
      else updateSelected.push(value);

      return {
        ...state,
        selectedTPAs: updateSelected,
      };
    }

    case r.FORMAT_AND_VALIDATE_DATE: {
      const { whichDate, formattedDate } = action.payload;
      const [, , formatErr] = checkDate(formattedDate);

      return {
        ...state,
        [`${whichDate}Filter`]: formattedDate,
        [`${whichDate}Error`]: formatErr,
      };
    }

    case r.CHECK_DATE_RANGE: {
      const dateRangeErr = checkRangeOfDate(state.startDateFilter, state.endDateFilter);

      if (
        dateRangeErr === dateErrors.needRange || // !startDate && endDate, so error goes on start date field
        dateRangeErr === dateErrors.startNotBeforeEnd ||
        dateRangeErr === dateErrors.moreThan90DaysFromToday
      ) {
        // startDate && !endDate && startDate >= 90 days, so error goes on end date field
        return {
          ...state,
          startDateError: dateRangeErr,
        };
      }
      if (dateRangeErr === dateErrors.moreThan90Days || dateRangeErr === '') {
        return {
          ...state,
          startDateError: dateRangeErr, // startDate && endDate but > 90 days, so both fields get error OR scrub previous errors if dateRangeErr === ''
          endDateError: dateRangeErr,
        };
      }
      return state;
    }

    case r.SELECT_ALL_IDS: {
      return {
        ...state,
        selectedIDs: [...state.selectedIDs, ...state.filteredAging.map(c => c.id)],
      };
    }

    case r.UPDATE_SELECTED_IDS: {
      const { id } = action.payload;
      let updateSelected = [...state.selectedIDs];
      if (state.selectedIDs.includes(id))
        updateSelected = state.selectedIDs.filter(val => val !== id);
      else updateSelected.push(id);

      return { ...state, selectedIDs: updateSelected };
    }

    case r.CLEAR_SELECTED_IDS: {
      return {
        ...state,
        selectedIDs: state.selectedIDs.filter(id => !action.payload.ids.includes(id)),
      };
    }

    case r.TOGGLE_VIEW_SELECTED_CLAIMS: {
      return { ...state, viewSelectedClaims: !state.viewSelectedClaims };
    }

    case r.UPDATE_FILTERED_AGING: {
      return {
        ...state,
        filteredAging: getFilteredAging(state),
      };
    }

    case r.SHOW_ONLY_AGING_CLAIMS: {
      return {
        ...state,
        showOnlyAging: !state.showOnlyAging,
        startDateFilter: '',
        endDateFilter: '',
        startDateError: '',
        endDateError: '',
      };
    }

    case r.UPDATE_AGING_SEARCH_TEXT: {
      return { ...state, agingSearchText: action.payload.agingSearchText };
    }

    case r.GET_DUPLICATES_PENDING: {
      return { ...state, loading: true, duplicates: [] };
    }

    case r.GET_DUPLICATES_REJECTED:
    case r.GET_DUPLICATES_FULFILLED: {
      return { ...state, loading: false, duplicates: action.payload.data || [] };
    }

    case r.GET_DAILY_CLAIMS_PENDING: {
      return { ...state, loading: true, dailyClaims: null };
    }

    case r.GET_DAILY_CLAIMS_REJECTED:
    case r.GET_DAILY_CLAIMS_FULFILLED: {
      return { ...state, loading: false, dailyClaims: action.payload.data || null };
    }

    case r.GET_INPROCESS_CLAIMS_PENDING: {
      return { ...state, loading: true, inProcessClaims: [] };
    }

    case r.GET_INPROCESS_CLAIMS_REJECTED:
    case r.GET_INPROCESS_CLAIMS_FULFILLED: {
      return { ...state, loading: false, inProcessClaims: action.payload.data || [] };
    }

    case r.FILTER_INPROCESS_CLAIMS: {
      return {
        ...state,
        inProcessSearchText: action.payload.inProcessSearchText,
        filteredInProcessClaims: getFilteredInProcess(
          state.inProcessClaims,
          action.payload.inProcessSearchText
        ),
      };
    }

    case r.UNASSIGN_CLAIM_OWNER_FULFILLED: {
      const unassign = claim => {
        if (claim.id !== action.meta) {
          return claim;
        }
        return { ...claim, owner: {} };
      };
      return {
        ...state,
        inProcessClaims: state.inProcessClaims.map(unassign),
        filteredInProcessClaims: state.filteredInProcessClaims.map(unassign),
      };
    }

    case r.GET_PATIENT_REPORT_PENDING: {
      return { ...state, loading: true, patients: [] };
    }

    case r.GET_PATIENT_REPORT_REJECTED:
    case r.GET_PATIENT_REPORT_FULFILLED: {
      return { ...state, loading: false, patients: action.payload.data || [] };
    }

    case r.FILTER_PATIENT: {
      return {
        ...state,
        filteredPatients: getFilteredPatients(state.patients, action.payload.patientSearchObject),
      };
    }

    case r.SORT_PATIENT: {
      const { sortBy, sortDirection } = action.payload;
      return {
        ...state,
        patients: sortPatients(state.patients, sortBy, sortDirection),
        filteredPatients: sortPatients(state.filteredPatients, sortBy, sortDirection),
      };
    }

    case r.GET_PLAN_REPORT_PENDING: {
      return { ...state, loading: true, patients: [] };
    }

    case r.GET_PLAN_REPORT_REJECTED:
    case r.GET_PLAN_REPORT_FULFILLED: {
      return { ...state, loading: false, plans: action.payload.data || [] };
    }

    case r.GET_FILE_PENDING: {
      return { ...state, selectedFile: {}, loadingFile: true };
    }
    case r.GET_FILE_FULFILLED: {
      return { ...state, selectedFile: action.payload.data, loadingFile: false };
    }
    case r.GET_FILE_REJECTED: {
      return { ...state, selectedFile: {}, loadingFile: false };
    }

    case r.GET_FILES_PENDING:
    case r.GET_FILES_V2_PENDING: {
      return { ...state, files: [], loading: true };
    }

    case r.GET_FILES_FULFILLED:
    case r.GET_FILES_V2_FULFILLED: {
      if (typeof action.payload.data === 'string') {
        // if response is html, clear search results
        return { ...state, files: [], loading: false };
      }

      return { ...state, files: action.payload.data, loading: false };
    }

    case r.GET_FILES_REJECTED:
    case r.GET_FILES_V2_REJECTED: {
      return { ...state, files: [], loading: false };
    }

    case r.GET_MONTHLY_REPORTING_PREFLIGHT_PENDING: {
      return {
        ...state,
        monthlyReporting: makeEmptyMonthlyReporting(),
        loading: true,
      };
    }

    case r.GET_MONTHLY_REPORTING_PREFLIGHT_FULFILLED: {
      const { month, year, message, duplicatesMinBilledAmount, data } = action.payload.data;

      return {
        ...state,
        monthlyReporting: {
          month,
          year,
          duplicatesMinBilledAmount,
          duplicateGroups: message == null ? data : undefined,
          errorMessage: message,
          isReportInProgress: message == null,
        },
        loading: false,
      };
    }

    case r.GET_MONTHLY_REPORTING_PREFLIGHT_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case r.RESOLVE_MONTHLY_REPORTING_DUPLICATE_PENDING: {
      const { groupIndex, claimIndex } = action.meta;

      return update(state, {
        monthlyReporting: {
          duplicateGroups: {
            [groupIndex]: {
              [claimIndex]: {
                isResolving: { $set: true },
              },
            },
          },
        },
      });
    }

    case r.RESOLVE_MONTHLY_REPORTING_DUPLICATE_FULFILLED: {
      const { isKeepDuplicate, groupIndex, claimIndex } = action.meta;

      return update(state, {
        monthlyReporting: {
          duplicateGroups: {
            [groupIndex]: {
              [claimIndex]: {
                $merge: {
                  keepDuplicate: isKeepDuplicate,
                  isResolving: false,
                },
              },
            },
          },
        },
      });
    }

    case r.RESOLVE_MONTHLY_REPORTING_DUPLICATE_REJECTED: {
      const { groupIndex, claimIndex } = action.meta;

      return update(state, {
        monthlyReporting: {
          duplicateGroups: {
            [groupIndex]: {
              [claimIndex]: {
                isResolving: { $set: false },
              },
            },
          },
        },
      });
    }

    case r.GENERATE_MONTHLY_REPORT_PENDING: {
      return {
        ...state,
        monthlyReporting: {
          ...state.monthlyReporting,
          errorMessage: '',
        },
        loading: true,
      };
    }

    case r.GENERATE_MONTHLY_REPORT_FULFILLED: {
      const { message, count } = action.payload.data;

      return {
        ...state,
        monthlyReporting: {
          ...state.monthlyReporting,
          isReportInProgress: message != null,
          errorMessage: message,
          numClaims: count,
          isReportGenerated: message == null,
        },
        loading: false,
      };
    }

    case r.GENERATE_MONTHLY_REPORT_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case r.GET_ALL_TPAS_CT_PENDING:
    case r.GET_ALL_PLANS_CT_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case r.GET_ALL_TPAS_CT_REJECTED:
    case r.GET_ALL_PLANS_CT_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case r.GET_ALL_TPAS_CT_FULFILLED: {
      const tpas = action.payload.data;
      tpas.sort((a, b) => (a.tpaName < b.tpaName ? -1 : 1));
      return {
        ...state,
        loading: false,
        tpas,
      };
    }

    case r.GET_ALL_PLANS_CT_FULFILLED: {
      const policies = action.payload.data;

      const seen = {};
      const plans = policies.reduce((acc, cur) => {
        if (!seen[cur.sixDegPlanID]) {
          seen[cur.sixDegPlanID] = cur;
          acc.push(cur);
        }
        return acc;
      }, []);
      plans.sort((a, b) => (a.planName < b.planName ? -1 : 1));

      return {
        ...state,
        loading: false,
        plans,
      };
    }

    case r.GET_EXCEPTIONS_REPORT_PENDING: {
      return {
        ...state,
        exceptionsReport: {
          ...state.exceptionsReport,
          exceptions: makeEmptyExceptionsReport(),
        },
        loading: true,
      };
    }

    case r.GET_EXCEPTIONS_REPORT_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        exceptionsReport: {
          ...state.exceptionsReport,
          exceptions: data.length === 0 ? [{ name: 'none' }] : data,
        },
        loading: false,
      };
    }

    case r.GET_EXCEPTIONS_REPORT_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case r.GET_TPA_EXCLUSIONS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case r.GET_TPA_EXCLUSIONS_FULFILLED: {
      return {
        ...state,
        exceptionsReport: {
          ...state.exceptionsReport,
          exclusions: action.payload.data,
        },
        loading: false,
      };
    }

    case r.GET_TPA_EXCLUSIONS_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export function moneyIncludes(num, substr) {
  return typeof num === 'number' && `$${num}`.includes(cleanBilledAmtText(substr));
}

export function cleanBilledAmtText(t) {
  return t.replace('$', '').replace(',', '');
}

const dateStringTypes = ['DateOfBirth', 'StartDate', 'EndDate'];

export function getFilteredPatients(patients, patientSearchObject) {
  if (!patientSearchObject || typeof patientSearchObject !== 'object') {
    return patients;
  }

  const searchTerms = getSearchTerms(patientSearchObject);
  return patients.filter(patient => allSearchTermsMatchPatient(searchTerms)(patient));
}

export function getSearchTerms(patientSearchObject) {
  const searchTerms = [];

  if (
    patientSearchObject.patientSearchFirstName &&
    typeof patientSearchObject.patientSearchFirstName === 'string'
  ) {
    searchTerms.push(patientSearchObject.patientSearchFirstName.toLowerCase().trim());
  }

  if (
    patientSearchObject.patientSearchLastName &&
    typeof patientSearchObject.patientSearchLastName === 'string'
  ) {
    searchTerms.push(patientSearchObject.patientSearchLastName.toLowerCase().trim());
  }

  if (patientSearchObject.searchDateofBirth) {
    const formattedDateOfBirth = formatPatientField(patientSearchObject.searchDateofBirth);
    if (formattedDateOfBirth) {
      searchTerms.push(formattedDateOfBirth);
    }
  }

  if (
    patientSearchObject.searchPatientID &&
    typeof patientSearchObject.searchPatientID === 'string'
  ) {
    searchTerms.push(patientSearchObject.searchPatientID.toLowerCase().trim());
  }

  if (
    patientSearchObject.searchSubscriberID &&
    typeof patientSearchObject.searchSubscriberID === 'string'
  ) {
    searchTerms.push(patientSearchObject.searchSubscriberID.toLowerCase().trim());
  }

  return searchTerms;
}

function allSearchTermsMatchPatient(searchTerms) {
  return patient => searchTerms.every(searchTermDoesMatch(patient));
}

function searchTermDoesMatch(patient) {
  const patientStrings = Object.values(patient).map(value => formatPatientField(value));
  return searchTerm =>
    patientStrings.some(patientString => patientStringDoesMatch(searchTerm, patientString));
}

function patientStringDoesMatch(searchTerm, patientString) {
  return patientString.includes(searchTerm);
}

function formatPatientField(value) {
  if (value instanceof Date) {
    return value.toISOString().slice(0, 10);
  }
  return String(value).toLowerCase();
}

export function formatForPatientSearch(key, value) {
  if (typeof value !== 'string') return '';
  if (dateStringTypes.includes(key)) return value.slice(0, 10);
  return value.toLowerCase();
}

function getFilteredInProcess(inProcessClaims, searchText) {
  if (!searchText) {
    return inProcessClaims;
  }
  const t = searchText.toLowerCase();
  return inProcessClaims.filter(c => {
    const ownerName = c.owner && c.owner.id ? c.owner.fullName || 'unnamed' : 'unassigned';
    return (
      doesStringArrayIncludeString(c.patients, t) ||
      doesStringArrayIncludeString(c.subscribers, t) ||
      moneyIncludes(c.billedAmt, t) ||
      doesStringArrayIncludeString(c.claimNums, t) ||
      doesStringArrayIncludeString(c.refNums, t) ||
      doesStringInclude(c.status, t) ||
      doesStringInclude(ownerName, t)
    );
  });
}

function filterByAgingSearch(searchText, filtered) {
  const t = searchText.toLowerCase();
  return filtered.filter(
    c =>
      doesStringInclude(c.billName, t) ||
      doesStringInclude(c.billLoc, t) ||
      doesStringInclude(c.billedAmt, t) ||
      doesStringArrayIncludeString(c.claimNums, t) ||
      doesStringArrayIncludeString(c.planIDs, t) ||
      doesStringArrayIncludeString(c.refNums, t) ||
      doesStringArrayIncludeString(c.subscribers, t) ||
      doesStringArrayIncludeString(c.patients, t) ||
      doesStringInclude(c.comment, t) ||
      (c.extStatusNote && doesStringInclude(c.extStatusNote.comment, t)) ||
      doesStringInclude(c.status, t) ||
      doesStringInclude(c.incomingFilename, t)
  );
}

export function sortPatients(patients, sortBy, sortDirection) {
  const unsortedPatients = [...patients];
  return unsortedPatients.sort((patientA, patientB) =>
    compare(patientA[sortBy], patientB[sortBy], sortDirection)
  );
}

export function compare(a, b, direction) {
  const aClean = typeof a === 'string' ? a.toLowerCase() : a;
  const bClean = typeof b === 'string' ? b.toLowerCase() : b;
  const dir = direction === SortDirection.DESC ? -1 : 1;
  if (aClean > bClean) {
    return dir;
  }
  if (aClean < bClean) {
    return -dir;
  }
  return 0;
}

export function getAllAging(aging) {
  return validArray(aging).sort(byDateReceived);
}

export function getAllTPAsInReport(aging) {
  const tpaNames = [];
  validArray(aging).forEach(claim => {
    if (claim.hasOwnProperty('tpa') && !tpaNames.includes(claim.tpa)) {
      tpaNames.push(claim.tpa);
    }
  });
  return tpaNames.sort();
}

function getFilteredAging(state) {
  let filtered = state.allAging;
  if (state.viewSelectedClaims) {
    filtered = filtered.filter(c => state.selectedIDs.includes(c.id));
  }
  if (state.agingSearchText) {
    filtered = filterByAgingSearch(state.agingSearchText, filtered);
  }
  if (state.selectedTPAs.length > 0) {
    filtered = filterByTPA(state.selectedTPAs, filtered);
  }
  return filtered;
}

function filterByTPA(selectedTPAs, filtered) {
  return filtered.filter(r => selectedTPAs.includes(r.tpa));
}

function checkRangeOfDate(start, end) {
  if (!start && !end) return '';
  if (start && !end) {
    if (!compareAgainstToday(start)) {
      return dateErrors.moreThan90DaysFromToday;
    }
    return '';
  }
  if (!start && end) {
    return dateErrors.needRange;
  }
  if (new Date(start) > new Date(end)) {
    return dateErrors.startNotBeforeEnd;
  }
  if (!compareDates(start, end)) {
    return dateErrors.moreThan90Days;
  }
  return '';
}

function compareAgainstToday(date) {
  return lessThan90Days(new Date(date), new Date());
}

function compareDates(start, end) {
  return lessThan90Days(new Date(start), new Date(end));
}

function lessThan90Days(start, end) {
  const diff = Math.abs(end.getTime() - start.getTime());
  return diff / (1000 * 60 * 60 * 24) <= 90;
}

export function byDateReceived(a, b) {
  if (a.hasOwnProperty('dateReceived') && b.hasOwnProperty('dateReceived')) {
    const d1 = new Date(a.dateReceived);
    const d2 = new Date(b.dateReceived);
    if (isValidDate(d1) && isValidDate(d2)) {
      return d1 - d2;
    }
  }
  return 0;
}
