import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';

import { getFile } from '../reportsActions';
import { RootState } from '../../shared/types/types';

function formatBatchContents(contents: unknown): string {
  return inflateString(contents)
    .replace(/~\r?\n/g, '~')
    .replace(/~ST\*/g, '~\nST*')
    .replace(/~LX\*/g, '~  LX*')
    .replace(/~/g, '~\n');
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '1rem',
    boxSizing: 'border-box',
    height: '100%',
    overflowY: 'scroll',
  },
  error: {
    color: colors.red,
  },
  warning: {
    color: colors.orange,
  },
});

type StateProps = {
  file?: RootState['reports']['selectedFile'];
  isLoading: RootState['reports']['loadingFile'];
};

type DispatchProps = {
  getFile: typeof getFile;
};

type ParentProps = {
  batchId: string;
};

const mapStateToProps = ({ reports }: RootState): StateProps => ({
  file: reports.selectedFile,
  isLoading: reports.loadingFile || false,
});

const mapDispatchToProps = {
  getFile,
};

type Props = StateProps & DispatchProps & ParentProps;

export function BatchDetail({ batchId, getFile, isLoading, file }: Props): JSX.Element | null {
  const classes = useStyles();
  useEffect(() => {
    if (batchId !== '') getFile(batchId);
  }, [batchId, getFile]);

  if (!batchId || !file) return null;

  const { filename, date, direction, claimIDs, error, warning, contents } = file;
  const formattedContents = formatBatchContents(contents);

  return (
    <div className={classes.container}>
      <LoadingSpinner isLoading={isLoading} />
      {!isLoading && (
        <div>
          <div>
            <strong>Filename:</strong> {filename}
          </div>
          <div>
            <strong>Date:</strong> {date}
          </div>
          <div>
            <strong>Direction:</strong> {direction}
          </div>
          <div>
            <strong>Claims ({claimIDs ? claimIDs.length : 0}):</strong>
            {Array.isArray(claimIDs) &&
              claimIDs.map((id, index) => (
                <span key={id}>
                  {index > 0 ? ', ' : ' '}
                  <Link to={`/repricing/${id}`}>{id}</Link>
                </span>
              ))}
          </div>
          {error && (
            <div>
              <strong>Error:</strong> <span className={classes.error}>{error}</span>
            </div>
          )}
          {warning && (
            <div>
              <strong>Warning:</strong> <span className={classes.warning}>{warning}</span>
            </div>
          )}
          <pre>{formattedContents}</pre>
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchDetail);
