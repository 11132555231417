import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { RouteMatchParams } from '../../shared/types/types';

import BatchList from './BatchList';
import BatchDetail from './BatchDetail';
import AdvancedBatchSearch, { advancedBatchesSearchHeight } from './AdvancedBatchSearch';

const useStyles = makeStyles({
  container: {
    height: `calc(100% - ${advancedBatchesSearchHeight})`,
    width: '100%',
    display: 'flex',
  },
});

export default function AdvancedBatches(): JSX.Element {
  const { id } = useParams<RouteMatchParams>();
  const batchId = id || '';
  const classes = useStyles();

  return (
    <>
      <AdvancedBatchSearch />
      <div className={classes.container}>
        <BatchList
          batchId={batchId}
          baseURL="/reports/advanced-batches/"
          showBatchesOnLoad={false}
          displayNumResults
        />
        <BatchDetail batchId={batchId} />
      </div>
    </>
  );
}
