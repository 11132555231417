import React, { useState, useEffect, FormEventHandler, ChangeEventHandler } from 'react';
import { connect } from 'react-redux';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { colors } from '@apps/shared/src/style';
import DateInput from '@apps/shared/src/components/DateInput';

import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { toISODateString } from '@apps/shared/src/utilities';
import { getFilesV2 } from '../reportsActions';
import { InputChangeHandler, RootState } from '../../shared/types/types';
import { FilesFilter } from '../types';
import { getPlans } from '../../edi/planEditor/plansActions';
import PlanSelect from './PlanSelect';
import TPASelect from './TPASelect';
import AdvancedBatchesHelpDialog from './AdvancedBatchesHelpDialog';

export const advancedBatchesSearchHeight: CSSProperties['height'] = '170px';

const useStyles = makeStyles({
  container: {
    padding: '5px 16px',
    borderBottom: `1px solid ${colors.grey12}`,
    boxSizing: 'border-box',
    height: advancedBatchesSearchHeight,
    overflowY: 'auto',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    margin: '0 0.5rem 0.5rem 0',
  },
  dateInput: {
    margin: '0 0.5rem 0.5rem 0',
    width: 200,
  },
  filenameInput: {
    margin: '0 0.5rem 0.5rem 0',
    width: 250,
  },
  directionInput: {
    margin: '0 0.5rem 0.5rem 0',
    width: 90,
  },
  submitButton: {
    marginRight: '0.5rem',
    alignSelf: 'center',
  },
  helpButton: {
    alignSelf: 'center',
  },
  selectLabel: {
    padding: '0 0.25rem',
  },
  selectItem: {
    padding: '0 1rem',
    fontSize: '0.75rem',
    minHeight: '1.5rem',
  },
});

type StateProps = {
  allPlans: RootState['plans']['allPlans'];
  allTPAs: RootState['plans']['allTPAs'];
};

type DispatchProps = {
  getFilesV2: typeof getFilesV2;
  getPlans: typeof getPlans;
};

type Props = StateProps & DispatchProps;

const mapDispatchToProps = { getFilesV2, getPlans };

const mapStateToProps = ({ plans }: RootState): StateProps => {
  return {
    allPlans: plans.allPlans,
    allTPAs: plans.allTPAs,
  };
};

function toDateString(date: Date): string {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
}

function getDefaultFilesFilter(): FilesFilter {
  const end = new Date();
  const start = new Date();
  start.setDate(start.getDate() - 7);
  return { startDate: toDateString(start), endDate: toDateString(end) };
}

export function AdvancedBatchSearch({
  getFilesV2,
  getPlans,
  allPlans,
  allTPAs,
}: Props): JSX.Element {
  const classes = useStyles();
  const [searchFilter, setSearchFilter] = useState<FilesFilter>(getDefaultFilesFilter());
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);

  const isTPAOrPlanSearch =
    searchFilter.sixDegTPAID !== undefined || searchFilter.sixDegPlanID !== undefined;

  useEffect(() => {
    if (Object.keys(allPlans).length === 0 || Object.keys(allTPAs).length === 0) {
      getPlans();
    }
  }, [getPlans, allPlans, allTPAs]);

  const handleTextChange: InputChangeHandler = e => {
    const { name, value } = e.target;
    setSearchFilter(filter => ({ ...filter, [name]: value }));
  };

  const handleDirectionChange: ChangeEventHandler<{ value: unknown }> = e => {
    const { value } = e.target;
    if (typeof value === 'string') setSearchFilter(filter => ({ ...filter, direction: value }));
    else setSearchFilter(({ direction, ...rest }) => rest);
  };

  const handleTPAChange = (sixDegTPAID?: number): void => {
    setSearchFilter(filter => ({ ...filter, sixDegTPAID }));
  };

  const handlePlanChange = (sixDegPlanID?: number): void => {
    setSearchFilter(filter => ({ ...filter, sixDegPlanID }));
  };

  const handleStartDateChange: (dateValue: string) => void = date => {
    if (date !== '') setSearchFilter(filter => ({ ...filter, startDate: date }));
    else setSearchFilter(({ startDate, ...rest }) => rest);
  };

  const handleEndDateChange: (dateValue: string) => void = date => {
    if (date !== '') setSearchFilter(filter => ({ ...filter, endDate: date }));
    else setSearchFilter(({ endDate, ...rest }) => rest);
  };

  const handleSubmit: FormEventHandler<Element> = e => {
    e.preventDefault();
    const filter: FilesFilter = { ...searchFilter };
    if (filter.startDate) filter.startDate = toISODateString(`${filter.startDate} 00:00:00`);
    if (filter.endDate) filter.endDate = toISODateString(`${filter.endDate} 23:59:59`);
    getFilesV2(filter);
  };

  const handleHelpButtonClick = (): void => setIsHelpDialogOpen(true);
  const handleHelpDialogClose = (): void => setIsHelpDialogOpen(false);

  return (
    <div className={classes.container}>
      <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
        <TPASelect allTPAs={allTPAs} onChange={handleTPAChange} />
        <PlanSelect
          allPlans={allPlans}
          onChange={handlePlanChange}
          selectedSixDegTPAID={searchFilter.sixDegTPAID}
        />
        <DateInput
          className={classes.dateInput}
          name="startDate"
          label="Start date"
          required={isTPAOrPlanSearch}
          value={searchFilter.startDate || ''}
          onChange={handleStartDateChange}
        />
        <DateInput
          className={classes.dateInput}
          name="endDate"
          label="End date"
          required={isTPAOrPlanSearch}
          value={searchFilter.endDate || ''}
          onChange={handleEndDateChange}
        />
        <FormControl className={classes.directionInput}>
          <InputLabel id="direction-label">Direction</InputLabel>
          <Select
            labelId="direction-label"
            id="direction"
            value={searchFilter.direction || ''}
            label="Direction"
            onChange={handleDirectionChange}
          >
            <MenuItem value="">
              <em>Both</em>
            </MenuItem>
            <MenuItem value="in">In</MenuItem>
            <MenuItem value="out">Out</MenuItem>
          </Select>
        </FormControl>
        <TextValidator
          className={classes.filenameInput}
          id="filename"
          label={isTPAOrPlanSearch ? 'Filename' : 'Filename/error'}
          name="searchText"
          value={searchFilter.searchText || ''}
          onChange={handleTextChange}
        />
        <TextValidator
          className={classes.input}
          id="clientName"
          label="Client name"
          name="clientName"
          value={searchFilter.clientName || ''}
          onChange={handleTextChange}
        />
        <Button className={classes.submitButton} variant="contained" color="primary" type="submit">
          Submit
        </Button>
        <IconButton
          aria-label="advanced-batches-help"
          color="primary"
          className={classes.helpButton}
          onClick={handleHelpButtonClick}
        >
          <HelpIcon />
        </IconButton>
      </ValidatorForm>
      <AdvancedBatchesHelpDialog open={isHelpDialogOpen} onClose={handleHelpDialogClose} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedBatchSearch);
