import { ElementType } from 'react';
import { Theme } from '@material-ui/core';
import { removeProvider } from '../contractsActions';
import { SimpleUser } from '../../shared/types/types';

export type ContractSummary = {
  id: number;
  startDate: string;
  endDate: string;
  status: ContractStatus;
  ownerID: string;
  patients: string[];
  providerNames: string[];
  providerNPIs: string[];
  providerTaxIDs: string[];
  patientSummary: string;
  providerGroup: string;
  contractType: ContractTypes;
};

export type ContractPatient = {
  id: number;
  memberID: string;
  name: string;
  dob: string;
  aliases: string[];
  address?: string;
};

export type ContractPlan = {
  id: number;
  planName: string;
  policyNum: string;
  planID: string;
};

export type ContractRoster = {
  id: number;
  name: string;
  isManual: boolean;
  lastModifiedDate: string;
  providers: ContractProvider[];
  providerCount: number;
  npiColumnName?: string;
  taxIDColumnName?: string;
};

export type ContractError = {
  title: string;
  message: string;
};

export type ContractProvider = {
  npi: string;
  npiError: string;
  name: string;
  taxID: string;
  taxIDError: string;
};

export type ProviderCell = 'npi' | 'taxID';

export type TermItem = keyof ContractTerm;

export type TermValue = string | number | boolean | string[] | undefined;

export type TermEntry = [string, TermValue];

export type RangeType = 'dollars' | 'days' | '';

export type ComparisonType = 'greater' | 'lesser' | '';

export type ContractOwnerTypes = 'internal' | 'external' | '';

export type ContractStatus = 'DataEntry' | 'Active' | string;

export type ContractTerm = {
  id?: number;
  percentCMS?: number;
  percentBilledCharges?: number;
  startRange?: number;
  endRange?: number;
  rangeInclusive?: boolean;
  rangeType?: RangeType;
  revCode?: string[];
  hcpcsOrCPT?: string[];
  unlistedCharges?: boolean;
  claimType?: string;
  limitedServices?: string;
  extraStipulations?: string;
  perDiem?: number;
  maxAmount?: number;
  flatRate?: number;
  caseRate?: number;
  comparisonType?: ComparisonType;
  openFormatTextTerm?: string;
};

export type ContractDetail = {
  id: number;
  status: ContractStatus;
  link: string;
  startDate: string;
  endDate: string;
  note: string;
  providerGroup: string;
  lastEditedBy: string[];
  contractType: ContractTypes;
  dataEnterer?: SimpleUser;
  owner?: SimpleUser;
  patients: ContractPatient[];
  rosters: ContractRoster[];
  plans: ContractPlan[];
  terms: ContractTerm[];
  contractOwnerType: ContractOwnerTypes;
};

export type EventsourceUpdate = {
  id: number;
  status: ContractStatus;
  owner?: SimpleUser;
  dataEnterer?: SimpleUser;
};

export type ContractUpdate = {
  id: number;
  action: string;
  intVal: number;
  stringVal: string;
  roster: ContractRoster;
};

export type AutocompleteOption = ContractPatient | ContractPlan;
export type AutocompleteOptions = AutocompleteOption[];
export type AutocompleteType = 'patient' | 'plan';

export type ContractState = {
  contracts: ContractSummary[];
  filteredContracts: ContractSummary[];
  selectedContract: ContractDetail;
  autocompleteOptions: AutocompleteOptions;
  autocompleteType?: AutocompleteType;
  searchText: string;
  listLoading: boolean;
  isContractLoading: boolean;
  currentTermValues: ContractTerm;
  isTermOpen: boolean;
  isOpenTermOpen: boolean;
  selectedTermItems: TermItem[];
  isRosterOpen: boolean;
  isRosterLoading: boolean;
  hasUnsavedRosterChanges: boolean;
  selectedRoster: ContractRoster;
  selectedProvider: ContractProvider;
  isPatientOpen: boolean;
  isPlanOpen: boolean;
  isNPILookupPending: boolean;
  contractBeingLoaded?: number;
};

export type SetIsElementOpen = (elementName: OpenableElement, open: boolean) => void;

export type HandleListClick = (id: number) => void;

export type UpdateContract = (action: string, value: string | number) => void;

export type HandleTermValueChange = (e: {
  target?: { value: TermValue; id: TermItem; type: string };
  value: TermValue;
  id: TermItem;
}) => void;

export type titleElements = { name?: string; planName?: string }[];

export type PatientSelectionType = 'patient' | 'plan';

export type ChipBundle = {
  items: object[];
  ItemChip: ElementType;
  handleRemoveItem?:
    | ((id: TermItem) => void)
    | typeof removeProvider
    | ((rosterID: number) => void);
  handleClickItem?:
    | ((id: ContractTerm) => void)
    | ((p: ContractProvider, index: number) => void)
    | ((rosterID: number) => void);
};

export type TermInputProps = {
  value: string[] | string | number;
  handleChange: HandleTermValueChange;
  id: TermItem;
  fullWidth?: boolean;
  maxLength?: number;
};

export type ListItem = { value: string; name: string };

export type ContractRosterTheme = Theme & {
  contractRoster: {
    table: {
      maxHeight: number;
      headerRowHeight: number;
      bodyRowHeight: number;
    };
  };
};

export type OpenableElement = 'roster' | 'patient' | 'plan' | 'term' | 'openTerm';
export type LoadableElement = 'contract';

// eslint-disable-next-line import/prefer-default-export
export enum ContractTypes {
  SingleCase = 'SingleCase',
  Direct = 'Direct',
  DirectOptIn = 'DirectOptIn',
  LOA = 'LOA',
  Invalid = 'Invalid',
}

export const ContractTypesDisplay = {
  SingleCase: 'Single Case Agreement',
  Direct: 'Direct Contract',
  DirectOptIn: 'Direct Contract (Opt In)',
  LOA: 'Letter of Agreement',
  Invalid: 'Invalid Contract Type',
};
